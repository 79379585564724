  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-7-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>Mur de soutènement</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Les pathologies courantes sur un mur de soutènement</h1>

<p>Destinés au maintien de massifs de terres de faibles hauteurs, ces petits ouvrages sont généralement construits en maçonnerie, sans armatures et sans conception par un bureau d’études.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Mur de soutènement" />
</div>


<p>Ces ouvrages sont donc vulnérables et soumis à des pathologies qui sont le plus souvent :</p>
<ul><li>des fissurations, un bombement,</li>
<li>des désordres accentués par l’action de l’eau mal maîtrisée,</li>
<li>la poussée des terres,</li>
<li>des poussées hydrostatiques</li></ul>

<p>L’expertise d’un mur de soutènement est souvent indispensable lorsque vous constatez des fissures, un bombement, un délitement de sa structure ou bien sa ruine.</p>

<h2>L’assistance de l’Expert technique </h2>

<p>Suite à des désordres constatés sur votre mur de soutènement,  l’Expert technique intervient dans différents cadres d’expertise :</p>

<ul><li>suite à un sinistre liée à une catastrophe naturelle (inondations, mouvement de terrain, sécheresse)</li>
<li>suite à un litige pour refus de garantie CatNat</li>
<li>avis technique constructif,</li>
<li>responsabilité civile.</li></ul>

<p>Nous vous remettons un rapport qui détermine les causes et origines des désordres constatés, un avis technique général de l’ouvrage, les moyens de réparation et leurs coûts.</p>



<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert</a>
    </div>
</div>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/mur-soutenement/' className='active'>Mur de soutènement</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details